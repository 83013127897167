import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setShowCookiesCons } from "../../common/actions/cookies";
export const COOKIE_NAME = "kutter-cookie";

const defaultCookies = {
  gtm: false,
  functions: false,
  c1: false,
  c2: false,
  c3: false,
};

function CookiesModal({ cookiesStore, setShowCookiesCons }) {
  const [preferences, setPreferences] = useState(defaultCookies);

  let location = useLocation();
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  useEffect(() => {
    if (!cookies[COOKIE_NAME]) {
      setShowCookiesCons(true, 1);
    }
  }, []);

  useEffect(() => {
    if (cookies[COOKIE_NAME]) {
      setPreferences({
        gtm: cookies[COOKIE_NAME].gtm,
        functions: cookies[COOKIE_NAME].functions,
        c1: cookies[COOKIE_NAME].c1,
        c2: cookies[COOKIE_NAME].c2,
        c3: cookies[COOKIE_NAME].c3,
      });
    }
  }, []);

  if (
    location.pathname === "/impressum" ||
    location.pathname === "/datenschutz"
  ) {
    return <></>;
  }

  function acceptAllCookies(e) {
    let timeout = 0;
    if (!preferences.c1 || !preferences.c2 || !preferences.c3) {
      timeout = 2000;
    }
    const values = {
      gtm: true,
      functions: true,
      c1: true,
      c2: true,
      c3: true,
    };
    setPreferences(values);
    saveCookies(timeout, values);
  }

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const constPreferencesCopy = { ...preferences };
    constPreferencesCopy[name] = value;

    setPreferences(constPreferencesCopy);
  };

  function saveCookies(timeout = 0, values) {
    setTimeout(() => {
      const date = new Date();
      date.setDate(date.getDate() + 30);

      setCookie(COOKIE_NAME, values || preferences, {
        expires: date,
        sameSite: true,
      });
      setShowCookiesCons(false);
    }, timeout);
  }

  const denyAll = () => {
    setPreferences(defaultCookies);
    saveCookies(2000, defaultCookies);
  };

  const renderMainMenu = () => {
    return (
      <div id="cookie-main" className="cookie-main-content">
        <h2>Wir nutzen Cookies</h2>
        <div className="cookie-intro">
          <p>
            Ihre Interessen sind uns wichtig. Um unsere Website dahingehend zu
            verbessern, sammeln wir Informationen zu Ihrem Besuch. Neben
            essenziellen Cookies, die zur Nutzung der Website notwendig sind,
            verwenden wir auch Cookies von Partnerseiten. Sie entscheiden, in
            welchem Umfang Sie Ihre Interessen mit uns teilen möchten, oder ob
            Sie die Verwendung nicht essenzieller Cookies{" "}
            <a onClick={denyAll} id="deny-all">
              ablehnen.
            </a>
          </p>
          <p>
            Ihre Daten werden verschlüsselt übertragen und Sie können Ihre
            Einwilligung jederzeit über unsere {" "}
            <a onClick={() => setShowCookiesCons(true, 2)} id="settings-link">
              „Cookie-Einstellungen“
            </a>{" "}
            ändern.
          </p>
        </div>
        <div className="cookie-form">
          <div className="cookie-form-left cookie-form-column">
            <div className="cookie-form-group">
              <label htmlFor="essentiell">
                <input
                  id="essentiell"
                  checked={true}
                  disabled={true}
                  type="checkbox"
                  className="uk-checkbox"
                  readOnly={true}
                />{" "}
                Essenzielle Cookies
              </label>
            </div>
            <div className="cookie-form-group">
              <label htmlFor="marketing">
                <input
                  id="marketing"
                  type="checkbox"
                  className="uk-checkbox"
                  checked={preferences.c2}
                  name="c2"
                  onChange={handleInputChange}
                />{" "}
                Marketing
              </label>
            </div>
          </div>
          <div className="cookie-form-right cookie-form-column">
            <div className="cookie-form-group">
              <label htmlFor="statistics">
                <input
                  id="statistics"
                  type="checkbox"
                  className="uk-checkbox"
                  checked={preferences.c1}
                  name="c1"
                  onChange={handleInputChange}
                />{" "}
                Statistiken
              </label>
            </div>
            <div className="cookie-form-group">
              <label htmlFor="externalmedia">
                <input
                  id="externalmedia"
                  type="checkbox"
                  className="uk-checkbox"
                  checked={preferences.c3}
                  name="c3"
                  onChange={handleInputChange}
                />{" "}
                Externe Medien
              </label>
            </div>
          </div>
        </div>
        <div className="cookie-form-bottom">
          <button
            id="select-all-button"
            className="cookie-button cookie-button-primary accept-all-button"
            onClick={acceptAllCookies}
          >
            <span>Alle auswählen</span>
          </button>
          <button
            id="save"
            className="cookie-button cookie-button-default"
            onClick={saveCookies}
          >
            <span>Speichern</span>
          </button>
        </div>
        <div className="cookie-form-extended">
          <div className="extended_settings">
            <span
              className="extended_settings-button"
              onClick={() => setShowCookiesCons(true, 2)}
            >
              Einstellungen
            </span>
          </div>
        </div>
        <div className="cookie-form-footer">
          <a href="/impressum" target="_blank" className="cookie-footer_link">
            Impressum
          </a>
          <a href="/datenschutz" className="cookie-footer_link">
            Datenschutz
          </a>
        </div>
      </div>
    );
  };

  const renderConfiguration = () => {
    return (
      <div className="cookie-settings-content">
        <div id="cookie-settings" className="cookie-main-content">
          <h2>Einstellungen</h2>
          <div className="cookie-settings-wrapper">
            <div className="cookie-setting">
              <div className="cookie-setting-left">
                <h4>Essenziell</h4>
                <p className="more-text toggle-1" hidden>
                  Essenzielle Cookies sind für die grundlegende und einwandfreie
                  Funktion unserer Website notwendig.
                </p>
                <a
                  className="show-more-button toggle-1"
                  uk-toggle="target: .toggle-1; animation: uk-animation-fade;"
                >
                  Mehr Informationen
                </a>
                <a
                  className="show-more-button open toggle-1"
                  hidden
                  uk-toggle="target: .toggle-1; animation: uk-animation-fade;"
                >
                  Weniger Informationen
                </a>
              </div>
              <div className="cookie-setting-right">
                <div className="ckbx-style-14">
                  <input
                    checked={true}
                    disabled={true}
                    type="checkbox"
                    id="check-essentiell"
                    value="0"
                    name="essentiell"
                  />
                  <label htmlFor="check-essentiell"></label>
                </div>
              </div>
            </div>
            <div className="cookie-setting">
              <div className="cookie-setting-left">
                <h4>Statistiken</h4>
                <p className="more-text toggle-2" hidden>
                  Statistik Cookies helfen uns dabei, das Verhalten unserer
                  Nutzer zu verstehen. Die Informationen werden anonym erfasst.
                </p>
                <a
                  className="show-more-button toggle-2"
                  uk-toggle="target: .toggle-2; animation: uk-animation-fade;"
                >
                  Mehr Informationen
                </a>
                <a
                  className="show-more-button open toggle-2"
                  hidden
                  uk-toggle="target: .toggle-2; animation: uk-animation-fade;"
                >
                  Weniger Informationen
                </a>
              </div>
              <div className="cookie-setting-right">
                <div className="ckbx-style-14">
                  <input
                    type="checkbox"
                    id="check-statistics"
                    checked={preferences.c1}
                    name="c1"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-statistics"></label>
                </div>
              </div>
            </div>
            <div className="cookie-setting">
              <div className="cookie-setting-left">
                <h4>Marketing</h4>
                <p className="more-text toggle-3" hidden>
                  Marketing Cookies verfolgen Besucher über unsere Website
                  hinaus, damit personalisierte Werbeinhalte von Drittanbietern
                  angezeigt werden können.
                </p>
                <a
                  className="show-more-button toggle-3"
                  uk-toggle="target: .toggle-3; animation: uk-animation-fade;"
                >
                  Mehr Informationen
                </a>
                <a
                  className="show-more-button open toggle-3"
                  hidden
                  uk-toggle="target: .toggle-3; animation: uk-animation-fade;"
                >
                  Weniger Informationen
                </a>
              </div>
              <div className="cookie-setting-right">
                <div className="ckbx-style-14">
                  <input
                    type="checkbox"
                    id="check-marketing"
                    checked={preferences.c2}
                    name="c2"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-marketing"></label>
                </div>
              </div>
            </div>
            <div className="cookie-setting">
              <div className="cookie-setting-left">
                <h4>Externe Medien</h4>
                <p className="more-text toggle-4" hidden>
                  Mit Ihrer Zustimmung für die Nutzung von Cookies externer
                  Medien erlauben Sie uns, Ihnen externe Inhalte wie z. B.
                  Video- und Social-Media-Plattformen zu zeigen. Eine manuelle
                  Zustimmung für den Zugriff auf diese Inhalte entfällt.
                </p>
                <a
                  className="show-more-button toggle-4"
                  uk-toggle="target: .toggle-4; animation: uk-animation-fade;"
                >
                  Mehr Informationen
                </a>
                <a
                  className="show-more-button open toggle-4"
                  hidden
                  uk-toggle="target: .toggle-4; animation: uk-animation-fade;"
                >
                  Weniger Informationen
                </a>
              </div>
              <div className="cookie-setting-right">
                <div className="ckbx-style-14">
                  <input
                    className=""
                    type="checkbox"
                    id="check-media"
                    value="0"
                    checked={preferences.c3}
                    name="c3"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-media"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="cookie-settings-bottom">
            <button
              onClick={() => setShowCookiesCons(true, 1)}
              id="back-button"
              className="cookie-button cookie-button-left cookie-button-primary"
            >
              <span>zurück</span>
            </button>

            <button
              className="cookie-button cookie-button-right cookie-button-primary accept-all-button"
              id="accept-all-button"
              onClick={acceptAllCookies}
            >
              <span>Alle akzeptieren</span>
            </button>
            <button
              className="cookie-button cookie-button-right cookie-button-default"
              id="confirm-button"
              onClick={saveCookies}
            >
              <span>Auswahl bestätigen</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Cookies are already configured and still valid.
  if (!cookiesStore.showCookiesCons) {
    return <div />;
  } else {
    return <div className="cookie-popup-wrapper">
      <div className="cookie-popup-content">
        {cookiesStore.cookiesModalPage === 1 && renderMainMenu()}

        {cookiesStore.cookiesModalPage === 2 && renderConfiguration()}
      </div>
    </div>
  }
}

function mapStateToProps({ cookies }) {
  return { cookiesStore: cookies };
}

const mapDispatchToProps = {
  setShowCookiesCons,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiesModal);
