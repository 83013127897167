export function getPath(children, val) {
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (!child.page || child.page.url !== val) {
      if (child.children) {
        const path = getPath(child.children, val);
        if (path) {
          path.unshift(child);
          return path;
        }
      }
    } else {
      return [child];
    }
  }
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
