import React, { useEffect, useState, useCallback } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { useCookies } from "react-cookie";
import TagManager from "react-gtm-module";
import { setBreadcrumb } from "./actions/breadcrumb";
import { getNavigations, getPagesForNavigation } from "./actions/navigation";
import CookiesModal from "../frontend/components/CookiesModal";

const Navigation = loadable(() =>
  import("../frontend/components/navigation/index")
);
const Newsletter = loadable(() => import("../frontend/components/Newsletter"));
const Footer = loadable(() => import("../frontend/components/Footer"));

const App = ({
  navigations,
  getNavigations,
  route,
  location,
  breadcrumb,
  setBreadcrumb,
  auth,
  getPagesForNavigation,
}) => {
  const [visibility, setVisibility] = useState(false);
  const [active, setActive] = useState(false);
  const [cookies] = useCookies(["kutter-cookie"]);

  const scrollEvent = useCallback(() => {
    if (window.pageYOffset > 200) setVisibility(true);
    else setVisibility(false);
  }, []);

  useEffect(() => {
    setBreadcrumb(location.pathname);
    if (
      cookies &&
      cookies["kutter-cookie"] &&
      cookies["kutter-cookie"].gtm &&
      !active
    ) {
      TagManager.initialize({
        gtmId: window.env.RAZZLE_GTM,
      });
      setActive(true);
    }

    window.addEventListener("scroll", scrollEvent);

    return () => window.removeEventListener("scroll", scrollEvent);
  }, [location.pathname, scrollEvent, cookies]);

  useEffect(() => {
    getNavigations();
  }, []);

  useEffect(() => {
    if (auth) getPagesForNavigation();
  }, []);

  function toTop() {
    if (visibility) {
      return (
        <div className="uk-position-bottom-right uk-position-fixed uk-animation-fade">
          <a
            className="uk-button-primary uk-padding-small"
            style={{ height: 16 }}
            href="#"
            data-uk-icon="chevron-up"
            data-uk-scroll=""
          />
          <div className="uk-hidden@l" style={{ height: 63 }} />
        </div>
      );
    } else {
      return <div />;
    }
  }

  return (
    <>
      <CookiesModal />
      <header>
        <Navigation
          navigation={navigations.find((n) => n.position === "navigation")}
          breadcrumb={breadcrumb}
        />
      </header>
      {renderRoutes(route.routes)}
      <Newsletter />
      <Footer id="footer" navigations={navigations} />
      {toTop()}
    </>
  );
};

function mapStateToProps({ navigations, breadcrumb, auth }) {
  return { navigations, breadcrumb, auth };
}

const mapDispatchToProps = {
  setBreadcrumb,
  getNavigations,
  getPagesForNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
