import { GET_BREADCRUMB, SET_BREADCRUMB } from "./types";
import { errorReport } from "../helpers/reporter";
import { getPath } from "../helpers/breadcrumb";

export const getBreadcrumb = options => async (dispatch, getState) => {
  try {
    return await dispatch({ type: GET_BREADCRUMB });
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const setBreadcrumb = url => async (dispatch, getState) => {
  try {
    return await dispatch({
      type: SET_BREADCRUMB,
      payload: getPath(getState().navigations, url) || []
    });
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
